<template>
  <div class="abtest-chart-container">
    <div class="control">
      <a-range-picker
        style="margin-right: 20px; width: 250px"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
        <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
      </a-range-picker>
      <a-select style="width: 120px" v-model="query.targetType" @change="getChartData">
        <a-select-option value="1">预估eCPM</a-select-option>
        <a-select-option value="2">流量请求</a-select-option>
        <a-select-option value="3">流量填充率</a-select-option>
        <a-select-option value="4">展示</a-select-option>
        <a-select-option value="5">展示率</a-select-option>
        <a-select-option value="6">填充耗时</a-select-option>
        <a-select-option value="7">展请率</a-select-option>
        <a-select-option value="8">DAU</a-select-option>
        <a-select-option value="9">DEU</a-select-option>
        <a-select-option value="10">请求eCPM</a-select-option>
      </a-select>
    </div>
    <a-spin :spinning="chartLoading">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getAbTestEchartsReport } from '@/api/aggregate'
import options from './options'
import { getDuration } from '@/utils/duration'
import * as echarts from 'echarts'
export default {
  mixins: [mixDate],
  data () {
    return {
      query: {
        targetType: '1',
        startDate: '',
        endDate: '',
        groupId: '',
        placeId: ''
      },
      colorList: [
        '#0055ff',
        '#ffab31',
        '#39da61',
        '#ff6688',
        '#a8a8ff',
        '#de7af9',
        'rgb(0, 118, 143)',
        'rgb(76, 180, 231)',
        'rgb(255, 192, 159)',
        'rgb(145, 78, 0)',
        '#ff746c'
      ],
      targeName: '',
      chartOption: options,
      chartLoading: false
    }
  },
  created () {
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.query.endDate = endDate
    this.query.startDate = startDate
  },
  mounted () {
    this.query.placeId = this.$route.query.placeId
    this.query.groupId = this.$route.query.groupId
    this.getChartData()
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.query = {
        ...this.query,
        endDate: dateString[1],
        startDate: dateString[0]
      }
      this.rangePickerDate = date
      this.getChartData()
    },
    async getChartData () {
      this.chartLoading = true
      const resp = await getAbTestEchartsReport(this.query)
      this.targetName =
        this.query.targetType === 1
          ? '预估eCPM'
          : this.query.targetType === 2
          ? '流量请求'
          : this.query.targetType === 3
          ? '流量填充率'
          : this.query.targetType === 4
          ? '展示'
          : this.query.targetType === 5
          ? '展示率'
          : this.query.targetType === 6
          ? '填充耗时'
          : this.query.targetType === 7
          ? '展请率'
          : this.query.targetType === 8
          ? 'DAU'
          : this.query.targetType === 9
          ? 'DEU'
          : ''
      this.chartOption.xAxis.data = resp.data.dateList
      //   tooltip
      this.chartOption.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item) => {
          const temp = {}
          if (this.query.targetType === '1') {
            temp.value = '￥' + item.value
          } else if (this.query.targetType === '3' || this.query.targetType === '5' || this.query.targetType === '7') {
            temp.value = item.value + '%'
          } else if (this.query.targetType === '6') {
            temp.value = item.value + 's'
          } else {
            temp.value = item.value
          }

          str += ` <div style='min-width:200px;display:flex;justify-content:space-between'><span>${item.marker}${item.seriesName}</span>${temp.value}</div>`
        })

        return (
          `<div style='min-width:300px;display:flex;justify-content:space-between'><span>${params[0].axisValue}</span>${this.targetName}</div>` +
          str
        )
      }
      this.chartOption.series = []
      this.chartOption.legend.data = []
      // data
      resp.data.abTestReportDataList.forEach((item, index) => {
        this.chartOption.legend.data.push(item.testGroupName)
        this.chartOption.series.push({
          name: item.testGroupName,
          type: 'line',
          smooth: true,
          yAxisIndex: 0,
          data: item.values,
          symbol: 'circle', // 设定为实心点
          symbolSize: 6, // 设定实心点的大小
          itemStyle: {
            normal: {
              color: this.colorList[index] || '#f40',
              lineStyle: {
                color: this.colorList[index] || '#f40',
                width: 2
              }
            }
          }
        })
      })
      this.echartsInit()
      this.chartLoading = false
    },
    echartsInit () {
      if (!this.$refs.chart) return
      this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
      this.myEcharts.setOption(this.chartOption)
    }
  }
}
</script>

<style lang="less">
.abtest-chart-container {
  border-radius: 5px;
  margin: 10px 10px;
  background: #fff;
  padding: 10px;
  .control {
    padding: 10px;
  }
  .chart {
    height: 400px;
  }
}
</style>
