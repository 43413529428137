export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },

    extraCssText: 'min-width: 200px'
  },
  legend: {
    data: []
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#000',
        width: 0.5
      }
    }
  },
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        show: true
      },
      position: '',
      axisLine: {
        lineStyle: {
          color: '#666',
          width: 0.5
        }
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: true
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 6, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: '#0055ff',
          lineStyle: {
            color: '#0055ff',
            width: 2
          }
        }
      }
    }
  ]
}
